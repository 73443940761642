import React, { useMemo } from "react";
import SideMenuSection from "component/sidemenu/SideMenuSection";
import SideMenuItem from "component/sidemenu/SideMenuItem";
import t from "module/translations";
import routes from "routes/routes";
import { useAppSelector } from "redux/hooks";
import { EntityIdentifier, EntityType } from "module/entity/types";
import useMaintenanceTaskCorrectiveCreate from "module/maintenanceTask/createCorrective/useMaintenanceTaskCorrectiveCreate";
import { ReactComponent as WorkOrderIcon } from "assets/settings.svg";
import MenuIcon from "layout/menu/MenuIcon";
import selectEntity from "module/staff/redux/selector/selectEntity";
import selectOpenItemsByEntityType from "module/staff/redux/selector/selectOpenItemsByEntityType";
import { MyMaintenanceTaskInterface } from "module/maintenanceTask/types";
import { MaintenanceTaskDefinitionType } from "pages/maintenanceTaskList/types";
import usePermissions from "module/permission/usePermissions";

const MaintenanceMenuSection: React.FC = () => {
  const selectEntityHelper = useAppSelector(selectEntity);
  const openMaintenanceTasksEntityIdentifier = useAppSelector(
    selectOpenItemsByEntityType(EntityType.maintenanceTask)
  ) as EntityIdentifier[];

  const openedMaintenanceTaskId = useMemo(
    () =>
      openMaintenanceTasksEntityIdentifier.reduce(
        (result, openEntityIdentifier) => {
          if (result == null) {
            const myMaintenanceTask = selectEntityHelper(
              openEntityIdentifier
            ) as MyMaintenanceTaskInterface;
            if (
              myMaintenanceTask.data.definitionType ===
              MaintenanceTaskDefinitionType.system
            ) {
              return openEntityIdentifier;
            }
          }
          return result;
        },
        null as EntityIdentifier | null
      ),
    [openMaintenanceTasksEntityIdentifier, selectEntityHelper]
  );

  const { canCreate } = useMaintenanceTaskCorrectiveCreate();
  const { canCreateMaintenanceTask, canEditMaintenanceTask } = usePermissions();
  const canCreateMaintenanceTaskFromTemplate =
    canCreateMaintenanceTask && canEditMaintenanceTask;

  return (
    <SideMenuSection
      {...{
        name: t.sideMenu.maintenanceTaskSection,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        icon: <MenuIcon {...{ Icon: WorkOrderIcon }} />,
      }}
    >
      <SideMenuItem
        {...{
          title: routes.maintenanceTasks.title || "",
          path: routes.maintenanceTasks.path,
          key: routes.maintenanceTasks.path,
        }}
      />
      <SideMenuItem
        {...{
          title: routes.myMaintenanceTasks.title || "",
          path: routes.myMaintenanceTasks.path,
          key: routes.myMaintenanceTasks.path,
        }}
      />
      {openedMaintenanceTaskId && (
        <SideMenuItem
          {...{
            title: routes.myOpenMaintenanceTaskDetails.title || "",
            path: routes.myOpenMaintenanceTaskDetails.path,
            key: routes.myOpenMaintenanceTaskDetails.path,
          }}
        />
      )}
      {canCreate && (
        <SideMenuItem
          {...{
            title: routes.createCorrectiveMaintenanceTask.title || "",
            path: routes.createCorrectiveMaintenanceTask.path,
            key: routes.createCorrectiveMaintenanceTask.path,
          }}
        />
      )}
      {canCreateMaintenanceTaskFromTemplate && (
        <SideMenuItem
          {...{
            title: routes.createMaintenanceTaskFromTemplate.title || "",
            path: routes.createMaintenanceTaskFromTemplate.path,
            key: routes.createMaintenanceTaskFromTemplate.path,
          }}
        />
      )}
    </SideMenuSection>
  );
};

export default React.memo(MaintenanceMenuSection);
